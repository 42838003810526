import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { IHttpOption, IRequestServer, IRequestType, IWebError, IWebResponse } from '../model/http.model';
import { AppConfigService } from './appconfig.service';
import { KeyService } from './key.service';
import { CrazyKey } from '../helper';
import { environment } from '@src/environments/environment';

@Injectable()
export class HttpService {
  // private _baseAddress = environment.apiGatewayUrl;
  // private _baseAddress = '';

  private apiBaseUrl: string = '';
  private defOption: IHttpOption = {
    RequestType: IRequestType.Protected,
    RequestServer: IRequestServer.Api,
    DefaultLogout: true,
    LoadingScreen: true
  };
  constructor(
    private _http: HttpClient,
    private _keyService: KeyService,
    private appConfigService: AppConfigService
  ) {
    // this.apiBaseUrl = 'http://gateway.dawn.dt';
  }
  get<T>(
    url: string,
    usrOption?: IHttpOption
  ): Observable<IWebResponse<T> | IWebError> {
    this.apiBaseUrl = CrazyKey.decrypt(this.appConfigService.appConfigDetails.apiBaseUrl, environment._privateKey);
    url = this.apiBaseUrl + url;
    // console.log("💕", url);
    // ! Merge Default & User Options
    usrOption = { ...this.defOption, ...usrOption };
    // ! Init Http Request
    return this._http.get<IWebResponse<T>>(
      url,
      { headers: this._handleHeaders(usrOption), params: usrOption.params }
    ).pipe(
      // ! Success Respose
      mergeMap((value: IWebResponse<T>) => this._handleData<T>(value, usrOption?.RequestServer)),
      // ! Error Respose
      catchError((err: HttpErrorResponse) => this._handleData<T>(err, usrOption?.RequestServer))
    );
  }
  post<T>(
    url: string,
    body: any,
    usrOption?: IHttpOption
  ): Observable<IWebResponse<T> | IWebError> {
    this.apiBaseUrl = CrazyKey.decrypt(this.appConfigService.appConfigDetails.apiBaseUrl, environment._privateKey);
    url = this.apiBaseUrl + url;
    
    // ! Merge Default & User Options
    usrOption = { ...this.defOption, ...usrOption };
    // this._http.post<IWebRespose<T>>(
    //this._handleUrl(url, usrOption),
    //   body,
    //   { headers: this._handleHeaders(usrOption), params: usrOption.params }
    // ! Init Http Request
    return this._http.post<IWebResponse<T>>(
      //this._handleUrl(url, usrOption),
      url,
      body,
      { headers: this._handleHeaders(usrOption), params: usrOption.params }
    ).pipe(
      // ! Success Respose
      mergeMap((value: IWebResponse<T>) => this._handleData<T>(value, usrOption?.RequestServer)),
      // ! Error Respose
      catchError((err: HttpErrorResponse) => this._handleData<T>(err, usrOption?.RequestServer))
    );
  }
  private _handleData<T>(event: IWebResponse<T> | HttpErrorResponse | any, options: IRequestServer | undefined)
    : Observable<IWebResponse<T> | IWebError> {
    return new Observable((subscribe) => {
      const webError = new IWebError(undefined);
      if (options === IRequestServer.Data) {
        subscribe.next({
          Code: event.code,
          Message: event.message,
          Data: event.data
        });
        // subscribe.complete();
      }
      else if (event instanceof HttpErrorResponse) {
        if (!navigator.onLine) {
          webError.Code = 520;
          webError.Message = `You're offline! 😢, please check your internet connection`;
        } else if (event.status === 0) {
          webError.Code = 0;
          webError.Message = 'server is not reachable! 😢, please check your internet connection';
        } else {
          if (event.status === 401 || event.status === 403) {
            webError.Code = event.status;
            webError.Message = event.statusText;
          } else {
            webError.Code = event.error.Code;
            webError.Message = event.error.Message;
            webError.Data = event.error.Data;
          }
        }
        subscribe.error(webError);
      }
      else if (event instanceof IWebError) {
        // ? DEVELOPER EXCEPTION
        if (event && (event.Code >= 2000.02 && event.Code <= 2000.99)) {
          // ! DO YOUR STUFF (Ex: Show error message)
        }
        // ? API-DB-ERROR
        else if (event && (event.Code >= 3000.01 && event.Code <= 3000.99)) {
          // ! DO YOUR STUFF (Ex: Redirect to error page)
        }
        // ? API-AUTHORIZATION-ERROR
        else if (event && (event.Code >= 4000.01 && event.Code <= 6000.99)) {
          // ! DO YOUR STUFF (Ex: Logout current session immediatly)
          //this._srvKey.SignOut();
        }
        subscribe.error(event);
      }
      else {
        if (event.Code === 200.0 || event.Code === 201.0) {
          subscribe.next(event);
        }
        else {
          webError.Code = event.Code;
          webError.Message = event.Message;
          webError.Data = event.Data;
          if (webError.Message.toLocaleLowerCase() == "model validation error") {
            if (event.RefData.length !== 0) {
              event.RefData.filter((res: any) => {
                res.Description.filter((e: any) => {
                  if (e !== "") webError.Message = e
                });
              });
            }
          }
          subscribe.error(webError);
        }
      }
    });
  }

  private _handleHeaders(options: IHttpOption, AllowContentType = false): { [header: string]: string | string[] } {
    const defaultHeader: { [header: string]: string } = {};
    if (AllowContentType) {
      defaultHeader['Content-Type'] = 'application/json; charset=utf-8';
    }
    switch (options.RequestType) {
      /*   case IRequestType.Guest:
          defaultHeader[CookieKey.Auth] = this._getRolloutAuthKey();
          break; */
      case IRequestType.Protected:
        defaultHeader['Authorization'] = `Bearer ${this._getRolloutApiKey()}`;
        break;
    }
    return Object.assign(options.headers || {}, defaultHeader);
  }
  public _getRolloutApiKey(): string {
    const apiKey = this._keyService.ApiKey;
    //const privateKey = CrazyKey.encode(this._keyService.PrivateKey);
    //const fpKey = CrazyKey.encode(this._keyService.FingerPrint);
    return apiKey;
  }
}
