export class CookieKey {
  public static readonly FingerPrint = '_-2';
  public static readonly ClientGuid = '_-1';
  public static readonly ProjectGuid = '_0';
  public static readonly DeveloperGuid = '_1';
  public static readonly DeveloperPassword = '_2';
  public static readonly Ip = '_3';
  public static readonly Agent = '_4';
  public static readonly Auth = '_5';
  public static readonly Api = '_6';
  public static readonly Url = '_7';
  public static readonly Mappedmenu = '8';
  public static readonly Shortcuts = '9';
  public static readonly Ref = '_-4';
  public static readonly ActiveTheme = '7';
}
