import { Injectable } from '@angular/core';
import { HttpService } from '@src/core/service';
import { IDepartments, IMenuList, IUpdateRoleMenu } from '../Models/roles';
import { IRequestType } from '@src/core/model';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(public http: HttpService) { }

  /* Get Departments and Job Title */
  GetDepartmentJobTitle() {
    return this.http.get<IDepartments>('/user-service/user/get-all-departments', { RequestType: IRequestType.Protected })
  }

  GetRoleMenu(AuthRoleID: any) {
    return this.http.get<IMenuList>(`/user-service/user/get-role-menu`, { params: { AuthRoleID }, RequestType: IRequestType.Protected })
  }

  /* Save Role Menu */
  SaveRoleMenu(data: IUpdateRoleMenu) {
    return this.http.post<IUpdateRoleMenu>('/user-service/user/update-role-menu', data, { RequestType: IRequestType.Protected })
  }


}
