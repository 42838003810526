import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { themeList } from '@src/app/shared/app-global';
import { CookieKey, CrazyKey } from '@src/core/helper';
import { IRequestType, IUser, Theme, UpdateTheme } from '@src/core/model';
import { AppService, HttpService, KeyService } from '@src/core/service';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-rightsidebar',
  templateUrl: './rightsidebar.component.html',
  styleUrls: ['./rightsidebar.component.scss']
})

/**
 * Rightsidebar component
 */
export class RightsidebarComponent implements OnInit {
  _privateKey: string = environment._privateKey;
  Themes = themeList;
  activeThemeType = Theme.ActiveThemeType;
  activeThemeName = Theme.ActiveThemeName;
  lightThemes: any[] = [];
  darkThemes: any[] = [];
  themeSidebar = false;
  LoginUserTheme = '';
  updateTheme: UpdateTheme = new UpdateTheme();
  userClaims: IUser | undefined;
  activeThemeKey = '';
  userName: string = '';


  constructor( @Inject(DOCUMENT) private document: Document,
  private _appService: AppService,
  private _httpService: HttpService,
  private _srvKey: KeyService) {
    this.lightThemes = this.Themes.filter((e) => e.themeType === 'light');
    this.darkThemes = this.Themes.filter((e) => e.themeType === 'dark');
    this.activeThemeName = Theme.ActiveThemeName;
    this.activeThemeType = Theme.ActiveThemeType;
  }

  ngOnInit() {
    this.activeThemeKey = this._srvKey.HashedCookieKey(CookieKey.ActiveTheme);
    const currentTheme = JSON.parse(
      localStorage.getItem(this.activeThemeKey) || '') as string;
    this.LoginUserTheme = CrazyKey.decrypt(currentTheme, this._privateKey);
    const isThemeExists = this.Themes.some((e) => e.themeName === this.LoginUserTheme);
    if (isThemeExists) {
      this.setTheme(this.LoginUserTheme);
    } else {
      this.setTheme('astronautBlue');
    }
  }

  /**
   * Hide the sidebar
   */
  public hide() {
    document.body.classList.remove('right-bar-enabled');
  }

  selectThemeType(selectedThemeType: any) {
    this.activeThemeType = selectedThemeType;
  }

  loadTheme(selectedThemeName: string) {
    this.updateTheme.ActiveTheme = selectedThemeName;
    this._httpService.post('/menu/updatetheme', this.updateTheme, { RequestType: IRequestType.Protected, }).subscribe({
      next: () => {
        localStorage.setItem(this.activeThemeKey, JSON.stringify(CrazyKey.encrypt(selectedThemeName, this._privateKey)));
        this.setTheme(selectedThemeName);
      },
    });
  }

  mouseOverTheme = '';
  MouseOver(data: string) {
    if (this.mouseOverTheme !== data) {
      this.mouseOverTheme = data;
      this.showTheme(this.mouseOverTheme);
    }
  }

  MouseLeave() {
    this.showTheme(this.LoginUserTheme);
  }

  setTheme(selectedThemeName: string) {
    Theme.ActiveThemeType = this.activeThemeType;
    Theme.ActiveThemeName =
      this.LoginUserTheme =
      this.activeThemeName =
      selectedThemeName;
    this.showTheme(selectedThemeName);
  }

  showTheme(theme: string) {
    let cssFile = theme.concat('-', this.activeThemeType).concat('.css');
    let headEl = this.document.getElementsByTagName('head')[0];
    const newLinkEl = this.document.createElement('link');
    newLinkEl.id = 'theme';
    newLinkEl.rel = 'stylesheet';
    newLinkEl.href = cssFile;
    let link = this.document.getElementById('theme');
    headEl.appendChild(newLinkEl);
    if (link) {
      link.parentNode?.removeChild(link);
    }
  }
}
