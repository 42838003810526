import { Injectable } from '@angular/core';
import { IRequestType } from '@src/core/model';
import { HttpService } from '@src/core/service';
import { ChangePassword } from '../model/password-settings.model';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {

  ChangePasswordURL = "/user-service/user/change-password";
  constructor(public http: HttpService) { }

  ChangePassword(data: ChangePassword) {
    return this.http.post<ChangePassword>(this.ChangePasswordURL, data, { RequestType: IRequestType.Protected });
  }

}
