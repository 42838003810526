import { LayoutModule } from '@progress/kendo-angular-layout';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { IconsModule } from '@progress/kendo-angular-icons';
import { InputsModule } from '@progress/kendo-angular-inputs';
import '@progress/kendo-angular-intl/locales/en-IN/all';
import '@progress/kendo-angular-intl/locales/en-GB/all';
import '@progress/kendo-angular-intl/locales/hi/all';
import { LabelModule } from '@progress/kendo-angular-label';
import { ListViewModule } from '@progress/kendo-angular-listview';
import { MapModule } from '@progress/kendo-angular-map';
import { MenuModule } from '@progress/kendo-angular-menu';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { RouteGuard } from '@src/core/guard/auth.guard';
import { AppService, HttpService, KeyService, LoaderService } from '@src/core/service';
import { AuthInterceptor } from '@src/core/service/auth.service';
import { UserService } from '@src/core/service/user.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocalString } from './shared/app-global';
import { AppConfigService } from '@src/core/service/appconfig.service';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { RolesService } from './admin/Role/service/roles.service';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LayoutsModule } from './layouts/layouts.module';
import { LayoutComponent } from './layouts/layout.component';

const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'admin',
    // path: 'commander',
    component: LayoutComponent,
    loadChildren: () => import('./layouts/layouts.module').then(m => m.LayoutsModule),
    canLoad: [RouteGuard],
    canActivate: [RouteGuard],
  },
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'auth',
    pathMatch: 'full'
  }
]

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    IconsModule,
    NotificationModule,
    ButtonsModule,
    DropDownsModule,
    GridModule,
    InputsModule,
    LabelModule,
    LayoutModule,
    ListViewModule,
    NavigationModule,
    ProgressBarModule,
    ToolBarModule,
    TooltipsModule,
    DateInputsModule,
    MenuModule,
    MapModule,
    // TelerikReportingModule,
    UploadsModule,
    LayoutsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    HttpService,
    LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderService, multi: true },
    AuthInterceptor,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    RolesService,
    HttpService,
    KeyService,
    RouteGuard,
    AppService,
    UserService,
    AppConfigService,
    { provide: LOCALE_ID, useValue: LocalString.LanguageCulture },
    {
      provide: APP_INITIALIZER, multi: true, deps: [AppConfigService],
      useFactory: (appConfigService: AppConfigService) => {
        return () => {
          return appConfigService.loadAppConfig();
        };
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
