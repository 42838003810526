import { AfterViewInit, Component, Inject, LOCALE_ID, ViewEncapsulation } from '@angular/core';
import { LoaderService } from '@src/core/service';
import { environment } from '@src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements AfterViewInit {
  title = 'Compass';
  constructor(private _loading: LoaderService, @Inject(LOCALE_ID) public localeId: string) {
    if (environment.production) {
      console.log = function () { };
    }

    // const onConfirmRefresh = function (event: { preventDefault: () => void; returnValue: string; }) {
    //   return event.returnValue = "Are you sure you want to reload the page?";
    // }

    // window.addEventListener("beforeunload", onConfirmRefresh);
  }

  ngAfterViewInit(): void {
    this._loading.hide();
  }

  // colorArray = [];

  ngOnInit() {
    // console.log("App component initialized");
    // const arrayValue = ["White", "Blue", "Blue", "White", "White", "Blue",  "Blue", "White", "Blue"];
    // console.log("start");
    // for(let i = 0; i < arrayValue.length ; i++){
    //   if(i === 0){
    //     console.log(arrayValue[i]);
    //   }
    //   else{
    //     console.log(arrayValue[i]);
    //     i++;
    //     console.log(arrayValue[i]);
    //   }
    // }
  }
}

