import { Menu } from './menu.model';

export const MENU: Menu[] = [
    {
        "subItems": [],
        "id": 101,
        "label": "CEO Dashboard",
        "link": "/admin/dashboard/ceo",
        "icon": "/assets/MenuIcons/dashboard/Dashboard.png",
        "parentId": 0,
        "isDefault": true
    },

    {
        "subItems": [],
        "id": 102,
        "label": "HRM Dashboard",
        "link": "/admin/dashboard/final-approval",
        "icon": "/assets/MenuIcons/dashboard/Dashboard.png",
        "parentId": 0,
        "isDefault": false
    },

    {
        "subItems": [],
        "id": 103,
        "label": "Incharage Dashboard",
        "link": "/admin/dashboard/first-approval",
        "icon": "/assets/MenuIcons/dashboard/Dashboard.png",
        "parentId": 0,
        "isDefault": false
    },

    {
        "subItems": [],
        "id": 104,
        "label": "User Dashboard",
        "link": "/admin/dashboard/developer",
        "icon": "/assets/MenuIcons/dashboard/Dashboard.png",
        "parentId": 0,
        "isDefault": false
    },

    /* Admin */
    {
        "subItems": [
            {
                "subItems": [],
                "id": 2,
                "label": "Roles",
                "link": "/admin/role",
                "icon": "/assets/MenuIcons/admin/roles.png",
                "parentId": 11,
                "isDefault": false
            },
            {
                "subItems": [],
                "id": 3,
                "label": "Job Title",
                "link": "/admin/user-job-title/user-department",
                "icon": "/assets/MenuIcons/admin/job-titles.png",
                "parentId": 11,
                "isDefault": false
            },
            {
                "subItems": [],
                "id": 4,
                "label": "Employees",
                "link": "/admin/employee",
                "icon": "/assets/MenuIcons/admin/employees.png",
                "parentId": 11,
                "isDefault": false
            },
        ],
        "id": 11,
        "label": "Admin",
        "link": "",
        "icon": "/assets/MenuIcons/admin/admin.png",
        "parentId": 0,
        "isDefault": false
    },

    /* Request */
    {
        "subItems": [
            {
                "subItems": [],
                "id": 5,
                "label": "Leave",
                "link": "/admin/request/leave",
                "icon": "/assets/MenuIcons/Commander/Leave.png",
                "parentId": 12,
                "isDefault": false
            },
            {
                "subItems": [],
                "id": 6,
                "label": "Permission",
                "link": "/admin/request/permission",
                "icon": "/assets/MenuIcons/Commander/Permission.png",
                "parentId": 12,
                "isDefault": false
            },
        ],
        "id": 12,
        "label": "Request",
        "link": "",
        "icon": "/assets/MenuIcons/Commander/Request.png",
        "parentId": 0,
        "isDefault": false
    },

    /* First Approval */
    {
        "subItems": [
            {
                "subItems": [],
                "id": 7,
                "label": "Leave Request List",
                "link": "/admin/first-approval/leave-request",
                "icon": "/assets/MenuIcons/Commander/LeaveRequestList.png",
                "parentId": 13,
                "isDefault": false
            },
            {
                "subItems": [],
                "id": 8,
                "label": "Permission Request List",
                "link": "/admin/first-approval/permission-request",
                "icon": "/assets/MenuIcons/Commander/PermissionRequestList.png",
                "parentId": 13,
                "isDefault": false
            },
            {
                "subItems": [],
                "id": 41,
                "label": "Leave Rejected List",
                "link": "/admin/first-approval/leave-rejected",
                "icon": "/assets/MenuIcons/Commander/LeaveRejectedList.png",
                "parentId": 13,
                "isDefault": false
            },
            {
                "subItems": [],
                "id": 48,
                "label": "Permission Rejected List",
                "link": "/admin/first-approval/permission-rejected",
                "icon": "/assets/MenuIcons/Commander/PermissionRejectedList.png",
                "parentId": 13,
                "isDefault": false
            },
        ],
        "id": 13,
        "label": "First Approval",
        "link": "",
        "icon": "/assets/MenuIcons/Commander/Approved.png",
        "parentId": 0,
        "isDefault": false
    },

    /* Final Approval */
    {
        "subItems": [
            {
                "subItems": [],
                "id": 31,
                "label": "Leave Request List",
                "link": "/admin/final-approval/leave-request",
                "icon": "/assets/MenuIcons/Commander/LeaveRequestList.png",
                "parentId": 45,
                "isDefault": false
            },
            {
                "subItems": [],
                "id": 32,
                "label": "Permission Request List",
                "link": "/admin/final-approval/permission-request",
                "icon": "/assets/MenuIcons/Commander/PermissionRequestList.png",
                "parentId": 45,
                "isDefault": false
            },
        ],
        "id": 45,
        "label": "Final Approval",
        "link": "",
        "icon": "/assets/MenuIcons/Commander/Approved.png",
        "parentId": 0,
        "isDefault": false
    },

    /* Pending */
    {
        "subItems": [
            {
                "subItems": [],
                "id": 9,
                "label": "Leave List",
                "link": "/admin/pending/leave",
                "icon": "/assets/MenuIcons/Commander/LeaveList.png",
                "parentId": 14,
                "isDefault": false
            },
            {
                "subItems": [],
                "id": 10,
                "label": "Permission List",
                "link": "/admin/pending/permission",
                "icon": "/assets/MenuIcons/Commander/PermissionList.png",
                "parentId": 14,
                "isDefault": false
            },
        ],
        "id": 14,
        "label": "Pending",
        "link": "",
        "icon": "/assets/MenuIcons/Commander/Pending.png",
        "parentId": 0,
        "isDefault": false
    },

    /* Approved */
    {
        "subItems": [
            {
                "subItems": [],
                "id": 21,
                "label": "Leave List",
                "link": "/admin/approved/leave",
                "icon": "/assets/MenuIcons/Commander/ApprovedLeave.png",
                "parentId": 15,
                "isDefault": false
            },
            {
                "subItems": [],
                "id": 22,
                "label": "Permission List",
                "link": "/admin/approved/permission",
                "icon": "/assets/MenuIcons/Commander/ApprovedPermission.png",
                "parentId": 15,
                "isDefault": false
            },
        ],
        "id": 15,
        "label": "Approved",
        "link": "",
        "icon": "/assets/MenuIcons/Commander/Approved.png",
        "parentId": 0,
        "isDefault": false
    },

    /* Rejected */
    {
        "subItems": [
            {
                "subItems": [],
                "id": 23,
                "label": "Leave List",
                "link": "/admin/rejected/leave",
                "icon": "/assets/MenuIcons/Commander/RejectedLeave.png",
                "parentId": 16,
                "isDefault": false
            },
            {
                "subItems": [],
                "id": 24,
                "label": "Permission List",
                "link": "/admin/rejected/permission",
                "icon": "/assets/MenuIcons/Commander/RejectedPermission.png",
                "parentId": 16,
                "isDefault": false
            },
        ],
        "id": 16,
        "label": "Rejected",
        "link": "",
        "icon": "/assets/MenuIcons/Commander/Rejected.png",
        "parentId": 0,
        "isDefault": false
    },

    /* Reports */
    {
        "subItems": [
            {
                "subItems": [],
                "id": 33,
                "label": "Leave Approved List",
                "link": "/admin/reports/leave-approved",
                "icon": "/assets/MenuIcons/Commander/LeaveApproved.png",
                "parentId": 477,
                "isDefault": false
            },
            {
                "subItems": [],
                "id": 34,
                "label": "Permission Approved List",
                "link": "/admin/reports/permission-approved",
                "icon": "/assets/MenuIcons/Commander/PermissionApproved.png",
                "parentId": 477,
                "isDefault": false
            },
            {
                "subItems": [],
                "id": 78,
                "label": "Leave Rejected List",
                "link": "/admin/reports/leave-rejected",
                "icon": "/assets/MenuIcons/Commander/LeaveRejected.png",
                "parentId": 477,
                "isDefault": false
            },
            {
                "subItems": [],
                "id": 79,
                "label": "Permission Rejected List",
                "link": "/admin/reports/permission-rejected",
                "icon": "/assets/MenuIcons/Commander/PermissionRejected.png",
                "parentId": 477,
                "isDefault": false
            },
        ],
        "id": 477,
        "label": "Reports",
        "link": "",
        "icon": "/assets/MenuIcons/Commander/Reports.png",
        "parentId": 0,
        "isDefault": false
    },
];

