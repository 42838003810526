import { HttpClient, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { Observable, Subscriber, Subscription } from "rxjs";
import { CookieKey } from "../helper";
import { RenewBearerToken } from "../model";
import { AppService } from "./app.service";
import { KeyService } from "./key.service";
import { LoaderService } from "./loader.service";
import { Router } from "@angular/router";
type CallerRequest = {
  subscriber: Subscriber<any>;
  failedRequest: HttpRequest<any>;
};
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshInProgress = false;
  private requests: CallerRequest[] = [];
  private refreshJWTRequest: Subscription;
  private refreshTknAPIURL = '/authorize/token/renew-for-tenant'
  constructor(private _srvCookie: CookieService, private http: HttpClient, private _appService: AppService, private _keyService: KeyService,
    private _loaderService: LoaderService, private router: Router) {
    this.refreshJWTRequest = Subscription.EMPTY;
    this.refreshInProgress = false;
  }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log("Interceptor",request);
    const observable = new Observable<HttpEvent<any>>((subscriber) => {
      const originalRequestSubscription = next.handle(request).subscribe((response) => {
        subscriber.next(response);
      },
        (error) => {
          if (error.status === 401) {
            if (request.url.includes(this.refreshTknAPIURL)) {
              this.refreshJWTRequest.unsubscribe();
              this.requests = [];
              this._keyService.SignOut();
              this._loaderService.hide();
            }
            else {
              this.handleUnauthorizedError(subscriber, request);
            }
          }
          else if (error.status === 403) {
            this.router.navigate(['/admin/error']);
          }
          else {
            // notification!!!
            subscriber.error(error);
          }
        },
        () => {
          subscriber.complete();
        });
      return () => {
        originalRequestSubscription.unsubscribe();
      };
    });
    return observable;
  }
  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({ setHeaders: { 'Authorization': `Bearer ${token}` } });
  }
  private handleUnauthorizedError(subscriber: Subscriber<any>, request: HttpRequest<any>) {
    if (!this.refreshInProgress) {
      this.refreshInProgress = true;
      const temp: RenewBearerToken = {
        RefreshToken: this._srvCookie.get(CookieKey.Ref)
      }

      this.refreshJWTRequest = this._appService.GetRefreshToken(temp).subscribe({
        next: (response) => {
          this.refreshInProgress = false;
          this.repeatFailedRequests(response.Data.BearerToken);
          this._keyService.SyncApiKey(response.Data);
          this._appService.user;
        },
        error: () => {
          // new CustomNotification(this.notificationService).ShowError(error.Message);
          this.refreshInProgress = false;
          this._keyService.SignOut();
          this._loaderService.hide();
        }
      }
      )
    }
    if (!request.url.includes(this.refreshTknAPIURL)) {
      // avoid refresh requests while refreshInProgress
      // this solves a small bug after logout()
      this.requests.push({ subscriber, failedRequest: request });
    }
  }
  private repeatFailedRequests(authHeader: string) {
    this.requests.forEach((c) => {
      const requestWithNewToken = this.addToken(c.failedRequest, authHeader);
      this.repeatRequest(requestWithNewToken, c.subscriber);
    });
    this.refreshJWTRequest.unsubscribe();
    this.requests = [];
  }
  private repeatRequest(requestWithNewToken: HttpRequest<any>, subscriber: Subscriber<any>) {
    this.http.request(requestWithNewToken).subscribe((res) => {
      subscriber.next(res);
    },
      (err) => {
        if (err.status === 401) {
          this._keyService.SignOut();
        }
        subscriber.error(err);
      },
      () => {
        subscriber.complete();
      });
  }
}
