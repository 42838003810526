import { NotificationService, Type } from "@progress/kendo-angular-notification";

export class ChipType {
  label: string = '';
  type: string = '';

}

// ** Auto close notification
export class NotificationConfig {
  static hideAfter: number = 2000;
  static position: { horizontal: 'right', vertical: 'top' };
  static animationType: "fade";
  static animationDuration: number = 500;
  static showIcon: boolean = true;
  static closable: boolean = false;
}

// ** Manually close notification
export class MessageNotificationConfig {
  static hideAfter: number = 6000;
  static position: { horizontal: 'right', vertical: 'top' };
  static animationType: "fade";
  static animationDuration: number = 500;
  static showIcon: boolean = true;
  static closable: boolean = false;
}

export class CustomNotification {
  constructor(private notificationService: NotificationService) {
  }

  // ** Auto close notification
  private ShowNotification(message: string, notiftype: Type) {
    this.notificationService.show({
      content: message,
      type: notiftype,
      position: NotificationConfig.position,
      animation: { type: NotificationConfig.animationType, duration: NotificationConfig.animationDuration },
      hideAfter: NotificationConfig.hideAfter,
      closable: NotificationConfig.closable,
    });
  }
  public ShowSuccess(message: string): void {
    this.ShowNotification(message, { style: 'success', icon: NotificationConfig.showIcon });
  }

  public ShowInfo(message: string): void {
    this.ShowNotification(message, { style: 'info', icon: NotificationConfig.showIcon });
  }

  public ShowError(message: string): void {
    this.ShowNotification(message, { style: 'error', icon: NotificationConfig.showIcon });
  }

  // ** Manually close notification
  private ShowMessageNotification(message: string, notiftype: Type) {
    this.notificationService.show({
      content: message,
      type: notiftype,
      position: MessageNotificationConfig.position,
      animation: { type: MessageNotificationConfig.animationType, duration: MessageNotificationConfig.animationDuration },
      hideAfter: MessageNotificationConfig.hideAfter,
      closable: MessageNotificationConfig.closable,
    });
  }

  public ShowNotificationMessage(message: string): void {
    this.ShowMessageNotification(message, { style: 'info', icon: MessageNotificationConfig.showIcon });
  }

  public ShowErrorMsg(message: string): void {
    this.ShowMessageNotification(message, { style: 'error', icon: MessageNotificationConfig.showIcon })
  }
}
