import { Component, OnInit, Output, EventEmitter, Inject, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { TabAlignment } from '@progress/kendo-angular-layout';
import { Align, PopupRef } from '@progress/kendo-angular-popup';
import { ChangePasswordComponent } from '@src/app/shared/components/change-password/change-password.component';
import { AppService, KeyService } from '@src/core/service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { CrazyKey } from '@src/core/helper';
import { environment } from '@src/environments/environment';
import { IUser } from '@src/core/model';
import { AppConfigService } from '@src/core/service/appconfig.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  element: any;
  cookieValue: any;
  flagvalue: any;
  countryName: any;
  valueset: any;

  constructor(@Inject(DOCUMENT) private document: any, private router: Router,
    public translate: TranslateService,
    public _cookiesService: CookieService,
    private _srvKey: KeyService,
    private dialogService: DialogService, private _appService: AppService,
    private _appConfigService: AppConfigService,
  ) {
  }

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  SysMenuSettings = [
    { title: 'Profile', iconClass: 'k-icon k-i-user' },
    { title: 'Themes', iconClass: 'k-i-palette k-icon' },
    { title: 'Change Password', iconClass: 'k-icon k-i-edit' },
    { title: 'Sign out', iconClass: 'k-i-logout k-icon' },
  ];
  showMenuSettingsOp = false;
  alignment: TabAlignment = 'start';
  drawerExpanded = true;
  item: any = {};
  anchorAlign: Align = { horizontal: 'right', vertical: 'bottom' };
  popupAlign: Align = { horizontal: 'right', vertical: 'top' };
  popup!: PopupRef | null;
  MenuToggle(popup: string, show?: boolean): void {
    switch (popup) {
      case 'MenuPopup': {
        this.showMenuSettingsOp =
          show !== undefined ? show : !this.showMenuSettingsOp;
        break;
      }
      default: {
        this.showMenuSettingsOp = false;
        break;
      }
    }
  }
  onMenuSelectedItem(item: string) {
    if (item == 'Change Password') {
      this.Password();
    } else if (item == 'Sign out') {
      this.SignOut();
    }
    else if (item == "Themes") {
      this.toggleRightSidebar();
    }
  }
  Password() {
    this.dialogService.open({
      title: 'Change Password',
      content: ChangePasswordComponent,
      width: 400,
      autoFocusedElement: 'oldPassword'
    });
  }
  SignOut() {
    this._srvKey.SignOut();
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }
  userName: string = '';
  userClaims: IUser | undefined;
  profileImage: string = 'assets/logo/user.png';

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;
    // this.userClaims = this._appService.user;
    let user = this._srvKey.HashedCookieKey("userName");
    const name = JSON.parse(localStorage.getItem(user) || '') as string;
    this.userName = CrazyKey.decrypt(name, environment._privateKey);
    // this.userName = CrazyKey.decrypt(this.userClaims?.UserName, environment._privateKey);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }
  @ViewChild('settingsAnchor') settingsAnchor!: ElementRef;
  @ViewChild('MenuAnchor') MenuAnchor!: ElementRef;
  @ViewChild('settingsPopup', { read: ElementRef }) settingsPopup!: ElementRef;
  @ViewChild('MenuPopup', { read: ElementRef }) MenuPopup!: ElementRef;
  @HostListener('keydown', ['$event'])
  keydown(event: any): void {
    if (event.keyCode === 27) {
      this.MenuToggle('default', false);
    }
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    this.contains(event.target);
  }

  contains(target: any) {
    let isExist: boolean;
    let eleRef =
      target.closest('button') === null
        ? 'default'
        : target.closest('button').getAttribute('name');
    switch (eleRef) {
      case 'MenuPopup': {
        isExist =
          this.MenuAnchor.nativeElement.contains(target) ||
          (this.MenuPopup
            ? this.MenuPopup.nativeElement.contains(target)
            : false);
        break;
      }
      default: {
        isExist = false;
      }
    }
    if (!isExist) {
      this.MenuToggle(eleRef, false);
    }
  }
}
