export class Theme {
  static ActiveThemeType = "light";
  static ActiveThemeName = "";
}
export class License {
  LicensedTo = '';
  LicenseInfo = '';
}
export class UpdateTheme {
  ActiveTheme: string = '';
}
