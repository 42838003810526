export class BearerToken {
  BearerToken: string = '';
  ValidFrom: string = '';
  ValidTo: string = '';
  RefreshToken: string = '';
  RefreshTokenValidTo: string = ''
  // MappedMenu: MappedMenuTree[] = []
}

export class MappedMenuTree {
  id: number | undefined = 0;
  text: string = '';
  icon: string = '';
  path: string | null = '';
  parentId: number = 0;
  //children: MappedMenuTree[] = [];
  //parent: boolean = false;
  expanded: boolean = false;
  selected: boolean = false;
  type: string = '';
  isDefault: boolean = false;
  SortOrder: number = 0;
  //level: number = 0;
}

export class LoginModel {
  LoginName: string = '';
  Password: string = '';
  PlatformID: string = '';
}

export class RenewBearerToken {
  RefreshToken: string = '';
}
