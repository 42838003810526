<!-- ========== Left Sidebar Start ========== -->

<ng-template #contentTemplate>
  <!--- Sidemenu -->
  <div id="sidebar-menu" style="margin-top: inherit; height: 100% !important;">
    <!-- Left Menu Start -->
    <ul class="metismenu list-unstyled" id="side-menu" #sideMenu>
      <!-- {{menuItems}} -->
      <ng-container *ngFor="let item of menuList">
        <li class="menu-title" *ngIf="item.isTitle">{{ item.label | translate }}</li>
        <li *ngIf="!item.isTitle && !item.isLayout">
          <!-- Mani Manu submenu is present-->
          <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
            [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}" style="text-decoration: none;">
            <img [src]="item.icon" alt="" *ngIf="item.icon">
            <span class="ml-1.5 align-middle"> {{ item.label | translate }}</span>
            <span class="badge rounded-pill float-end bg-{{item.badge.variant}}" *ngIf="item.badge">{{item.badge.text |
              translate}}</span>
          </a>
          <!-- Main header not submenu present -->
          <a routerLink="{{item.link}}" *ngIf="!hasItems(item)" class="side-nav-link-ref sigle-menu"
            routerLinkActive="active" style="text-decoration: none;">
            <img [src]="item.icon" alt="" *ngIf="item.icon">
            <span class="ml-1.5 align-middle"> {{ item.label | translate }}</span>
            <span class="badge rounded-pill bg-{{item.badge.variant}} float-end" *ngIf="item.badge">{{item.badge.text |
              translate}}</span>
          </a>
          <!-- Submenu -->
          <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
            <li *ngFor="let subitem of item.subItems">
              <!-- Only submenu -->
              <a routerLink="{{subitem.link}}" *ngIf="!hasItems(subitem)" class="side-nav-link-ref sub-sidemenu"
                [attr.data-parent]="subitem.parentId" style="text-decoration: none;" routerLinkActive="active">
                <img [src]="subitem.icon" alt="" *ngIf="subitem.icon">
                <span class="ml-1.5 align-middle">{{ subitem.label | translate}}</span>
                <span class="badge rounded-pill bg-{{subitem.badge.variant}} float-end"
                  *ngIf="subitem.badge">{{subitem.badge.text |
                  translate}}</span>
              </a>
              <!-- Submenu present in submenu  -->
              <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow has-arrow-show"
                href="javascript:void(0);" [attr.data-parent]="subitem.parentId" style="text-decoration: none;">
                <img [src]="subitem.icon" alt="" *ngIf="subitem.icon">
                <span class="ml-1.5 align-middle">{{ subitem.label | translate}}</span>
              </a>
              <!-- Submenu item in submenu -->
              <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                <li *ngFor="let subSubitem of subitem.subItems">
                  <a [attr.data-parent]="subSubitem.parentId" style="text-decoration: none;"
                    routerLink="{{subSubitem.link}}" routerLinkActive="active" class="side-nav-link-ref">
                    <img [src]="subSubitem.icon" alt="" *ngIf="subSubitem.icon">
                    <span class="ml-1.5 align-middle">{{ subSubitem.label | translate }}</span>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </div>

  <div class="drawer-footer">
    <!-- footer-button -->
    <div class="grid grid-cols-4">
      <div>
        <button kendoButton class="footer-button" title="Profile">
          <kendo-icon name="user" class="cursor-pointer"></kendo-icon>
        </button>
      </div>
      <div>
        <button type="button" kendoButton class="footer-button" title="Themes" (click)="ChangeTheme()">
          <kendo-icon name="palette" class="cursor-pointer">
          </kendo-icon>
        </button>
      </div>
      <div>
        <button kendoButton class="footer-button" title="Full Screen" (click)="fullscreen()">
          <kendo-icon name="fullscreen" class="cursor-pointer"></kendo-icon>
        </button>
      </div>
      <div>
        <button kendoButton class="footer-button" title="Sign out" (click)="SignOut()">
          <kendo-icon name="logout" class="cursor-pointer">
          </kendo-icon>
        </button>
      </div>
    </div>

    <!-- Licensed To -->
    <div class="footer-license">
      <div *ngIf="ShowLicenseInfo">{{licenseObj.LicenseInfo}}</div>
    </div>
  </div>
</ng-template>
<!-- Sidebar -->

<div class="vertical-menu">
  <ngx-simplebar class="h-100" #componentRef>
    <div *ngIf="!isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
    <div *ngIf="isCondensed">
      <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
    </div>
  </ngx-simplebar>
</div>
<!-- Left Sidebar End -->