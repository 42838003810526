import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieKey, CrazyKey, Guru } from '@src/core/helper';
import { environment } from '@src/environments/environment';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { BearerToken, MappedMenuTree } from '../model/sign-in';
import jwt_decode from "jwt-decode";
import { IUser } from '../model';
import { Menu } from '@src/app/layouts/sidebar/menu.model';

@Injectable()
export class KeyService {

  constructor(private _srvCookie: CookieService, private router: Router) { }
  public isApiKeyReady(): boolean {
    let bearerToken = this._srvCookie.get(CookieKey.Api);
    return bearerToken ? true : false;
  }
  public isApiKeyRefTokenReady(): boolean {
    let refToken = this._srvCookie.get(CookieKey.Ref);
    return refToken ? true : false;
  }

  public get ApiKey(): string {
    try {
      const apiCookieArr = this._srvCookie.get(CookieKey.Api);
      if (!Guru.isValidObj(apiCookieArr)) {
        return '';
      }
      return apiCookieArr;
    } catch (error) {
      return '';
    }
  }

  public SyncApiKey(authData: BearerToken): void {
    const tokenExpiryDiffInMinutes = this.diff_minutes(new Date(authData.ValidFrom), new Date(authData.ValidTo));
    const refTokenExpiryDiffInMinutes = this.diff_minutes(new Date(), new Date(authData.RefreshTokenValidTo));
    const tokenExpiryDate = tokenExpiryDiffInMinutes / 1400;
    const refTokenExpiryDate = refTokenExpiryDiffInMinutes / 1400;

    this._srvCookie.set(CookieKey.Api, authData.BearerToken, tokenExpiryDate, '/', undefined, undefined, 'Lax');
    this._srvCookie.set(CookieKey.Ref, authData.RefreshToken, refTokenExpiryDate, '/', undefined, undefined, 'Lax');
    localStorage.setItem(this.HashedCookieKey(CookieKey.ActiveTheme), JSON.stringify(jwt_decode<IUser>(authData.BearerToken).ActiveTheme));
  }

  menu: Menu = new Menu();
  menuList: Menu[] = [];
  public SyncRoleMenu(roleMenu: MappedMenuTree[]) {
    this.menuList = [];
    roleMenu.forEach((e) => {
      this.menu = new Menu(); // Create a new instance for each iteration
      this.menu.id = e.id;
      this.menu.label = e.text;
      this.menu.link = e.path;
      this.menu.icon = e.icon;
      // this.custom.icon = "/assets/MenuIcons/calendar.png";
      this.menu.parentId = e.parentId;
      this.menu.isDefault = e.isDefault;
      if (e.parentId == 0) {
        this.menuList.push(this.menu);
      } else {
        const parentItem = this.findItemById(this.menuList, e.parentId);
        if (parentItem) {
          if (!parentItem.subItems) {
            parentItem.subItems = [];
          }
          parentItem.subItems.push(this.menu);
        }
      }
    });
    const Encoded = CrazyKey.encrypt(JSON.stringify(this.menuList), environment._privateKey);
    const hashMenuKey = this.HashedCookieKey(CookieKey.Mappedmenu);
    localStorage.setItem(hashMenuKey, Encoded);
  }
  // Helper function to find an item by ID in a list or its subitems
  findItemById(list: Menu[], id: number): Menu | undefined {
    for (const item of list) {
      if (item.id === id) {
        return item;
      }
      if (item.subItems) {
        const foundItem = this.findItemById(item.subItems, id);
        if (foundItem) {
          return foundItem;
        }
      }
    }
    return undefined;
  }

  diff_minutes(dt2: Date, dt1: Date) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));
  }

  public HashedCookieKey(str: string): string {
    return CrazyKey.hash(str, environment._privateKey);
  }

  public SignOut(): void {
    this._srvCookie.delete(CookieKey.Api, '/', undefined, undefined, 'Lax');
    this._srvCookie.delete(CookieKey.Ref, '/', undefined, undefined, 'Lax');
    localStorage.clear();
    this.router.navigate(['auth/sign-in']);
  }
}


