<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <!-- <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="/assets/logo/app-logo-small.png" alt="" height="22" class="mini-logo">
          </span>
          <span class="logo-lg">
            <img src="/assets/logo/app-logo-black.png" alt="" height="17" class="large-logo">
          </span>
        </a> -->

        <div class="logo logo-light">
          <span class="logo-sm">
            <img src="/assets//logo/bot/box-white.png" alt="" class="mini-logo"
              [ngStyle]="{'height': '30px', 'margin-top' : '8px'}">
          </span>
          <span class="logo-lg">
            <img src="/assets/logo/bot/logo1-white.png" alt="" height="19" class="large-logo">
          </span>
        </div>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex">
      <!-- <div class="dropdown d-none d-lg-inline-block ms-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div> -->

      <div class="userName">{{userName}}</div>

      <button kendoButton #MenuAnchor name="MenuPopup" (click)="MenuToggle('MenuPopup')" title="Profile"
        class="k-button k-button-clear">
        <img class="rounded-circle header-profile-user float-left" [src]="profileImage" alt="Header Avatar">
      </button>

      <!-- <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="toggleRightSidebar()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div> -->
    </div>
  </div>
</header>
<kendo-popup [anchor]="MenuAnchor" #MenuPopup [anchorAlign]="anchorAlign" [popupAlign]="popupAlign"
  (anchorViewportLeave)="showMenuSettingsOp = false" *ngIf="showMenuSettingsOp" class="whitespace-nowrap">
  <kendo-card class="w-full p-0">
    <ul class="p-0 m-0">
      <a *ngFor="let SysMenuSts of SysMenuSettings" class="no-underline">
        <li (click)="onMenuSelectedItem(SysMenuSts.title)" class="k-card-header cursor-pointer p-2 hover:bg-gray-100">
          <span class="mr-1"><i [class]="SysMenuSts.iconClass"></i></span>
          {{SysMenuSts.title}}
        </li>
      </a>
    </ul>
  </kendo-card>
</kendo-popup>

<div kendoDialogContainer></div>