import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, UrlTree } from '@angular/router';
import { AppService } from '@src/core/service';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { CookieKey } from '../helper';
import { IUser } from '../model';

@Injectable()
export class RouteGuard  {
  // private PrivateKey: string = "SystemicNowBoxofTech@2022";
  private _user!: IUser | undefined;
  constructor(
    private _router: Router,
    private _srvCookie: CookieService,
    private _appSrv: AppService
  ) { }
  // canLoad(_route: Route, segments: UrlSegment[])
  //   : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
  //   return this.isAuthorizedUser();
  // }
  canLoad(_route: Route)
    : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.isAuthorizedUser();
  }

  private isAuthorizedUser(): boolean {
    let bearerToken = this._srvCookie.get(CookieKey.Api);
    let refreshToken = this._srvCookie.get(CookieKey.Ref);
    if (bearerToken) {
      this._user = this._appSrv.user;
      if (this._user) {
        return true;
      }
      else {
        return false;
      };
    }
    else if (refreshToken) {
      return true;
    }
    else {
      this._router.navigate(['./auth/sign-in']);
      return false;
    }
  }

  canActivate(_route: ActivatedRouteSnapshot)
    : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if (this.isAuthorizedUser()) {
      return true;
    }
    else {
      this._router.navigate(['./auth/sign-in']);
      return false;
    }
  }

}
