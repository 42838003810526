import * as CryptoJS from 'crypto-js';
export class CrazyKey {
  private static defaultkey = "Systemic30082014";
  private static get asciiSet(): { key: string; val: number }[] {
    return [
      {
        key: 'A',
        val: 13,
      },
      {
        key: 'B',
        val: 12,
      },
      {
        key: 'C',
        val: 2,
      },
      {
        key: 'D',
        val: 15,
      },
      {
        key: 'E',
        val: 11,
      },
      {
        key: 'F',
        val: 3,
      },
      {
        key: 'G',
        val: 9,
      },
      {
        key: 'H',
        val: 18,
      },
      {
        key: 'I',
        val: 14,
      },
      {
        key: 'J',
        val: 26,
      },
      {
        key: 'K',
        val: 16,
      },
      {
        key: 'L',
        val: 17,
      },
      {
        key: 'M',
        val: 19,
      },
      {
        key: 'N',
        val: 1,
      },
      {
        key: 'O',
        val: 4,
      },
      {
        key: 'P',
        val: 25,
      },
      {
        key: 'Q',
        val: 6,
      },
      {
        key: 'R',
        val: 20,
      },
      {
        key: 'S',
        val: 8,
      },
      {
        key: 'T',
        val: 22,
      },
      {
        key: 'U',
        val: 7,
      },
      {
        key: 'V',
        val: 24,
      },
      {
        key: 'W',
        val: 10,
      },
      {
        key: 'X',
        val: 21,
      },
      {
        key: 'Y',
        val: 23,
      },
      {
        key: 'Z',
        val: 5,
      },
    ];
  }
  private static readonly options = {
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
    iv: CryptoJS.enc.Utf8.parse('podaparathesino1'),
  };
  private static initKey(isSuffix: boolean): string {
    const alpha = this.genRandomChar(1);
    const random =
      this.asciiSet.find((i) => i.key === alpha.toUpperCase())?.val || 0;
    const key =
      (isSuffix ? '' : alpha) +
      this.genRandomChar(random) +
      (isSuffix ? alpha : '');
    return key;
  }
  private static genRandomChar(length: number): string {
    let result = '';
    const characters = 'LZwIepmPBoknUSNOQDqfRigjlEvKbxAsFtMHrVuCcdWhYGyTJaXz';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  /**
   * Encode a plain text using CrazyKey Algorithm
   * @param str string to decode rollout string
   * @returns string
   */
  public static encode(str: string): string {
    try {
      return CrazyKey.initKey(false) + str + CrazyKey.initKey(true);
    } catch (error) {
      return '';
    }
  }

  /**
   * Decode a CrazyKey encoded text as plain text
   * @param str string to decode rollout string
   * @returns string
   */
  public static decode(str: string): string {
    try {
      const preAlpha = str.charAt(0);
      const preNum =
        (this.asciiSet.find((i) => i.key === preAlpha.toUpperCase())?.val ||
          0) + 1;

      const sufAlpha = str.charAt(str.length - 1);
      const sufNum =
        (this.asciiSet.find((i) => i.key === sufAlpha.toUpperCase())?.val ||
          0) + 1;

      const startIdx = preNum;
      const endIdx = str.length - preNum - sufNum;

      const orgKey = str.substr(startIdx, endIdx);
      console.log("💞", orgKey);
      return orgKey;
    } catch (error) {
      return '';
    }
  }

  /**
   * Encrypt the plain text
   * @param str string to encrypt
   * @param privatekey key to encrypt value
   * @returns string
   */
  public static encrypt(str: string, privatekey: string): string {
    try {
      const plainText = CryptoJS.enc.Utf8.parse(str.trim());
      const key = CryptoJS.enc.Utf8.parse(privatekey.trim());
      const encrypted = CryptoJS.AES.encrypt(
        plainText,
        CryptoJS.MD5(key),
        this.options
      ).toString();
      return encrypted.replace(/\+/g, '__').replace(/\//g, '@@');
    } catch (error) {
      return '';
    }
  }


  /**
   * Decrypt the encrypted text
   * @param str string to decrypt
   * @param privatekey key to decrypt value
   * @returns string
   */
  public static decrypt(str: string, privatekey: string): string {
    try {
      str = str.replace(/@@/g, '/').replace(/__/g, '+');
      const encryptedText = str.trim();
      const key = privatekey === '' ? this.defaultkey : privatekey.trim();
      const md5Key = CryptoJS.MD5(key);
      const decrypted = CryptoJS.AES.decrypt(encryptedText, md5Key, this.options).toString(CryptoJS.enc.Utf8);
      return decrypted;
    } catch (error) {
      return '';
    }
  }


  /**
   * hash any plain text
   * @param str string to hash
   * @param privatekey key to hash value
   * @returns string
   */
  public static hash(str: string, privatekey: string): string {
    return CryptoJS.HmacSHA1(str, privatekey).toString(CryptoJS.enc.Hex);
  }
}
