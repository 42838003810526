import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DialogRef } from "@progress/kendo-angular-dialog";
import { TextBoxComponent } from "@progress/kendo-angular-inputs";
import { NotificationService } from "@progress/kendo-angular-notification";
import { ChangePassword } from "../../model/password-settings.model";
import { CustomNotification } from "../../model/shared";
import { PasswordService } from "../../service/password.service";
import { InputRegex } from "../regex";


@Component({
  selector: 'change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit {
  @ViewChild('newPassword', { static: false }) public newPassword!: TextBoxComponent;
  @ViewChild('oldPassword', { static: false }) public oldPassword!: TextBoxComponent;
  @ViewChild('confirmPassword', { static: false }) public confirmPassword!: TextBoxComponent;
  @Input() public passwordDetails!: FormGroup;
  opened = false;
  isValid = true;
  PasswordRegex = InputRegex.Password;
  changePassword: ChangePassword = new ChangePassword();
  form: FormGroup = new FormGroup({
    oldPassword: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [Validators.required]),
    confirmPassword: new FormControl('', [Validators.required,])
  });

  constructor(private notificationService: NotificationService, public dialog: DialogRef, private _srvPassword: PasswordService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.confirmPassword.input.nativeElement.type = "password";
    this.newPassword.input.nativeElement.type = "password";
    this.oldPassword.input.nativeElement.type = "password";
  }

  close() {
    this.opened = false;
  }

  change(data: any) {
    if (this.form.status == 'INVALID') {
      this.form.markAllAsTouched();
    }
    if (this.form.valid) {
      if (this.form.controls['newPassword'].value === this.form.controls['confirmPassword'].value) {
        this.changePassword.OldLoginPassword = data.oldPassword;
        this.changePassword.NewLoginPassword = data.newPassword;
        this._srvPassword.ChangePassword(this.changePassword).subscribe({
          next: (response) => {
            this.opened = false;
            this.dialog.close();
            this.form.reset();
            new CustomNotification(this.notificationService).ShowSuccess(response.Data);
          },
          error: (error) => {
            new CustomNotification(this.notificationService).ShowError(error.Message);
          }
        })
      }
      else {
        new CustomNotification(this.notificationService).ShowError("Check new password and confirm passoword are same..!");
      }
    }
  }

  toggleVisibility(password: string): void {
    let passwordRef: TextBoxComponent = this.oldPassword;
    switch (password) {
      case 'new': {
        passwordRef = this.newPassword;
        break;
      }
      case 'confirm': {
        passwordRef = this.confirmPassword;
        break;
      }
    }
    const inputEl = passwordRef.input.nativeElement;
    inputEl.type = inputEl.type === "password" ? "text" : "password";
  }

}
