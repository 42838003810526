export interface IPasswordsetting {
  oldpassword: string;
  newpassword: string;
  confirmpassword: string;
}

export class ChangePassword {
  OldLoginPassword: string = '';
  NewLoginPassword: string = '';
}
