import { DropDownFilterSettings } from "@progress/kendo-angular-dropdowns";
import { environment } from "@src/environments/environment";
import { CustomNotification } from "./model/shared";
import { NotificationService } from "@progress/kendo-angular-notification";
import { IWebError } from "@src/core/model";

export class Theme {
  static ActiveThemeType = "";
  static ActiveThemeName = "";
}

export class License {
  LicensedTo = '';
  LicenseInfo = '';
}

export class Subscriptions {
  static state = false;
}
export class IsActiveTenant {
  static IsActive: boolean = false;
}

// User List screen
export class UserListType {
  static ActiveListType = "Grid";
}

// Project List screen
export class ShowInactive {
  static ShowInactiveProject = false;
  static ShowInactiveBranch = false;
  static ShowInactiveUser = false;
  static ShowInactiveAgent = false;
  static ShowInactiveCustomer = false;
  static ShowInactiveBank = false;
  static ShowInactiveVendor = false;
  static ShowInactiveEmployee = false;
}

export class ThemesList {
  themeType = "";
  themeName = "";
  displayName = "";
  displayColor = "";
}

export const themeList: ThemesList[] = [
  { themeType: 'light', themeName: 'kashmirBlue', displayName: 'Kashmir Blue', displayColor: 'background-color: #58639b;' },
  { themeType: 'light', themeName: 'pacificBlue', displayName: 'Pacific Blue', displayColor: 'background-color: #12a1a3' },
  { themeType: 'light', themeName: 'astronautBlue', displayName: 'Astronaut Blue', displayColor: 'background-color: #0f446c' },
  { themeType: 'light', themeName: 'persianBlue', displayName: 'Persian Blue', displayColor: 'background-color: #222fb9' },
  { themeType: 'light', themeName: 'elfGreen', displayName: 'Elf Green', displayColor: 'background-color: #008d70' },
  { themeType: 'light', themeName: 'aquaDeep', displayName: 'Aqua Deep', displayColor: 'background-color: #0a4450' },
  { themeType: 'light', themeName: 'mustardBrown', displayName: 'Mustard Brown', displayColor: 'background-color: #a57c11' },
  { themeType: 'light', themeName: 'pastelBrown', displayName: 'Pastel Brown', displayColor: 'background-color: #876154' },
  { themeType: 'light', themeName: 'magnesioGrey', displayName: 'Magnesio Grey', displayColor: 'background-color: #424242' },
  { themeType: 'light', themeName: 'minimalGrey', displayName: 'Minimal Grey', displayColor: 'background-color: #f3f3f3' },
  { themeType: 'dark', themeName: 'gunmetalGrey', displayName: 'Gunmetal Grey', displayColor: 'background-color: #2e3446' },
  { themeType: 'dark', themeName: 'darkJungle', displayName: 'Dark Jungle', displayColor: 'background-color: #1f1f1f' }
]

export class LocalString {
  static Agent: string = "Agent";
  static AgentCaps: string = LocalString.Agent.toUpperCase();
  static LocaleID: string = "";
  static CurrencySymbol: string = "";
  static CurrencyCode: string = "";
  static Currency: string = "";
  static LanguageCulture: string = "en-IN"
  static UserName: string = "";
  static LicenseInfo: string = "";
  static DateTimeFormat: string = "";
  static DateFormat: string = "";
  static TimeFormat: string = "";
  static TenantID: string = "";
}

export const perVal: string = "";

export class GlobalMapValues {
  static zoomSize: number = 0;
  static center: number[] = [];
}

export class DawnService {
  static log(message: string, ...args: any[]): void {
    if (!environment.production) {
      console.log(message, ...args);
    }
  }
}

export class DateConversion {
  static userClaims: any;
  static response: Date = new Date();
  static ConvertToDateMonthYear(str: any) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    const res = [day, mnth, date.getFullYear()].join('-');
    return res;
  }

  static ConvertToYearMonthDate(str: any) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    const res = [date.getFullYear(), mnth, day].join('-');
    return res;
  }

  static ConvertMonthDateYear(str: any) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    const res = [mnth, day, date.getFullYear()].join('-');
    return res;
  }

  static ConvertToYearDateMonth(str: any) {
    var date = new Date(str),
      mnth = ('0' + (date.getMonth() + 1)).slice(-2),
      day = ('0' + date.getDate()).slice(-2);
    const res = [date.getFullYear(), day, mnth].join('-');
    return res;
  }

  static ConvertToMonthDateYear(str: any) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2)
    const res = [mnth, day, date.getFullYear()].join("/");
    return res;
  }

  static ConvertStringToDate(str: string) {
    const date = parseInt(str.split('-')[0]);
    const month = parseInt(str.split('-')[1]) - 1;
    const year = parseInt(str.split('-')[2]);
    this.response = new Date(year, month, date);
    return this.response;
  }

  static ConvertDateToString(str: Date) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    const res = [mnth, day, date.getFullYear()].join("-");
    return res;
  }
}

export class FileIcons {
  static getIcons(fileType: any) {
    switch (fileType) {
      case ('png'):
      case ('jpeg'):
      case ('jpg'):
      case ('svg'): {
        return "k-icon k-i-image";
      }
      case ('xlsx'):
      case ('xls'): {
        return "k-icon k-i-excel";
      }
      case ('csv'): {
        return "k-icon k-i-file-csv";
      }
      case ('pdf'): {
        return "k-icon k-i-file-pdf";
      }
      case ('pptx'): {
        return "k-icon k-i-file-ppt";
      }
      case ('docx'): {
        return "k-icon k-i-file-word";
      }
      case ('txt'): {
        return "k-icon k-i-file-txt";
      }
      case ('zip'): {
        return "k-icon k-i-file-zip";
      }
      default: {
        return "k-icon k-i-attachments"
      }
    }
  }
}

/* Grid Header Class */
export class gridFontStyle {
  gridClass: string;
  gridColumn: string;
  gridColumnGroup: string;

  constructor(public FontSize: string = 'small') {
    // this.groupHeaderClass = 'info-grid-' + this.FontSize + '-group-header';
    // this.columnHeaderClass = 'info-grid-' + this.FontSize + '-header';
    // this.gridDataRowClass = 'info-grid-' + this.FontSize + '-datarow';
    // this.gridOptionColClass = 'info-grid-' + this.FontSize + '-header-center';

    this.gridClass = 'grid-' + FontSize + '-class';
    this.gridColumn = 'grid-' + FontSize + '-column';
    this.gridColumnGroup = 'grid-' + FontSize + '-column-group';

  }
}

/* Date Range Style */
export class DateSize {
  small: string;
  medium: string;
  large: string;

  constructor(public width: string = 'small') {
    // this.groupHeaderClass = 'info-grid-' + this.width + '-group-header';
    // this.columnHeaderClass = 'info-grid-' + this.width + '-header';
    // this.gridDataRowClass = 'info-grid-' + this.width + '-datarow';
    // this.gridOptionColClass = 'info-grid-' + this.width + '-header-center';

    this.small = 'date-' + width;
    this.medium = 'date-' + width;
    this.large = 'date-' + width;
  }
}


/* Dropdown Filter */
export class DropDownFilter {
  /* Dropdown Filter Startwith Letters */
  static filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'startsWith',
  };

  /* Dropdown Filter Contains Letters */
  static filterSettingsContains: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
}

/* Gender Dropdown */
export class Gender {
  static LstGender: string[] = ['Male', 'Female', 'Non Binary'];
}

export class IRefreshToken {
  static refreshTokenName: string = '';
}

export class botWidget {
  static redColor = '#cb0606';
  static greenColor = '#00b886';
  static blueColor = '#0380B5';
  static violetColor = '#9242D2';
  static orangeColor = '#da9000';
}

/* Convert string to time format */
export class ConvertTimeToHours {
  static convertHours(NoofHours: string): string {
    const data = NoofHours.split(':');
    var strToNum1 = +data[0];
    var strToNum2 = +data[1];
    var result = '';

    if (((1 < strToNum1) && (strToNum2 == 0)) || ((1 <= strToNum1) && (strToNum2 != 0))) result = "Hours";
    else if (((1 == strToNum1) && (strToNum2 == 0)) || ((1 > strToNum1) && (strToNum2 != 0))) result = "Hour";
    return result;
  }
}

/* Show Error Msg */
export class Msg {
  constructor(protected _srvNotification: NotificationService) { }

  errorMsg(err: string) {
    new CustomNotification(this._srvNotification).ShowError(err);
  }

  successMsg(res: string) {
    new CustomNotification(this._srvNotification).ShowSuccess(res);
  }
}

