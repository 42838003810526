<form autocomplete="off" novalidate>
  <div>
    <ng-container [formGroup]="form">
      <kendo-formfield>
        <kendo-floatinglabel class="w-full" text="Old Password">
          <kendo-textbox formControlName="oldPassword" #oldPassword id="oldPassword" required [clearButton]="true"
            placeholder="Password">
            <ng-template kendoTextBoxSuffixTemplate>
              <button kendoButton look="clear" icon="eye" (click)="toggleVisibility('old')"></button>
            </ng-template>
          </kendo-textbox>
        </kendo-floatinglabel>
        <kendo-formhint><br></kendo-formhint>
        <kendo-formerror>Old Password is required</kendo-formerror>
      </kendo-formfield>

      <kendo-formfield>
        <kendo-floatinglabel class="w-full" text="New Password">
          <kendo-textbox formControlName="newPassword" #newPassword required [clearButton]="true" placeholder="Password"
            [pattern]="PasswordRegex">
            <ng-template kendoTextBoxSuffixTemplate>
              <button kendoButton look="clear" icon="eye" (click)="toggleVisibility('new')"></button>
            </ng-template>
          </kendo-textbox>
        </kendo-floatinglabel>
        <kendo-formhint><br></kendo-formhint>
        <kendo-formerror *ngIf="form.controls['newPassword'].errors?.['required']"> New password is required
        </kendo-formerror>
        <kendo-formerror *ngIf="form.controls['newPassword'].errors?.['pattern']">Enter valid password
        </kendo-formerror>
      </kendo-formfield>

      <kendo-formfield>
        <kendo-floatinglabel class="w-full" text="Confirm Password">
          <kendo-textbox formControlName="confirmPassword" #confirmPassword required [clearButton]="true"
            placeholder="Password">
            <ng-template kendoTextBoxSuffixTemplate>
              <button kendoButton look="clear" icon="eye" (click)="toggleVisibility('confirm')"></button>
            </ng-template>
          </kendo-textbox>
        </kendo-floatinglabel>
        <kendo-formhint><br></kendo-formhint>
        <kendo-formerror *ngIf="form.controls['confirmPassword'].errors?.['required']"> Confirm password is required
        </kendo-formerror>
      </kendo-formfield>
    </ng-container>
  </div>
  <div class="k-actions k-actions-end">
    <button type="submit" kendoButton (click)="change(form.value)" class="button-success">Update</button>
  </div>
</form>
