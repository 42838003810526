import { Injectable } from '@angular/core';
import { CookieKey, Guru } from '@src/core/helper';
import { ILoginResponse, IRequestType, IUser, IWebError, IWebResponse, MappedMenuTree } from '@src/core/model';
import jwt_decode from "jwt-decode";
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { HttpService } from './http.service';
import { KeyService } from './key.service';
export interface AppNotify {
  user: string;
  /** App data specifies the stored key,  default: `app` */
  menu: string;
}
@Injectable()
export class UserService {
  private _user!: IUser | undefined;
  // private _privateKey: string = "BOXOFTECH@2022@415";
  private _mappedMenus!: MappedMenuTree[] | undefined;
  private notify$ = new Subject<AppNotify>();
  get menus(): MappedMenuTree[] {
    try {
      if (!this._mappedMenus) {
        const _menuCookieKey = this._srvKey.HashedCookieKey(CookieKey.Mappedmenu);
        const _menuCookieStr = localStorage.getItem(_menuCookieKey) || '';
        if (!Guru.isValidString(_menuCookieStr)) {
          return [];
        }
        this._mappedMenus = JSON.parse(_menuCookieStr) as MappedMenuTree[];
      }
      return this._mappedMenus;
    } catch (error) {
      return [];
    }
  }
  get user(): IUser | undefined {
    try {
      if (!this._user) {
        let bearerToken = this._srvCookie.get(CookieKey.Api);
        this._user = jwt_decode<IUser>(bearerToken);
        /* const apiCookieArr = (this._srvCookie.get(CookieKey.Api) || '').split('.');
        if (!Guru.isValidObj(apiCookieArr) || apiCookieArr.length !== 2) {
          return undefined;
        }
        const userStr = CrazyKey.decrypt(CrazyKey.decode(apiCookieArr[1]), this._privateKey);
        if (userStr !== '') {
          this._user = JSON.parse(userStr) as IUser;
        } */
      }
      return this._user;
    } catch (error) {
      return undefined;
    }
  }
  get notify(): Observable<AppNotify> {
    return this.notify$.asObservable();
  }
  constructor(
    private _http: HttpService,
    private _srvCookie: CookieService,
    private _srvKey: KeyService
  ) {
  }

  SignIn(Username: string, Passoword: string, RememberMe: boolean): Observable<IWebResponse<ILoginResponse> | IWebError> {
    return this._http.get<ILoginResponse>(`auth/login/${Username}/${Passoword}/${RememberMe}`, {
      RequestType: IRequestType.Guest
    });
  }
  SignOut(): void {
    this._srvKey.SignOut();
  }
}

