export interface MenuItem {
    id?: number;
    label?: string;
    icon?: string;
    link?: string;
    subItems?: any;
    isTitle?: boolean;
    badge?: any;
    parentId?: number;
    isLayout?: boolean;
}


export class Menu {
    id?: number;
    label?: string;
    icon?: string;
    link?: string;
    isTitle?: boolean;
    badge?: any;
    parentId?: number;
    isLayout?: boolean;
    isDefault?: boolean;
    subItems?: SubItems[] = [];
}

export class SubItems {
    id?: number;
    label?: string;
    icon?: string;
    link?: string;
    subItems?: SubItems[] = [];
    isTitle?: boolean;
    badge?: any;
    parentId?: number;
    isLayout?: boolean;
    isDefault?: boolean;
}
