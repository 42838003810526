import { Component, OnInit, AfterViewInit, Inject, LOCALE_ID, HostListener, ChangeDetectionStrategy } from '@angular/core';
import { EventService } from '@src/core/service/event.service';

// import { EventService } from '../core/services/event.service';

import {
  LAYOUT_VERTICAL, LAYOUT_HORIZONTAL, LAYOUT_WIDTH, TOPBAR, LAYOUT_MODE, SIDEBAR_TYPE
} from './layouts.model';
import { LocalString } from '../shared/app-global';
import { environment } from '@src/environments/environment';
import { CldrIntlService, IntlService } from '@progress/kendo-angular-intl';
import { CrazyKey } from '@src/core/helper';
import { IUser } from '@src/core/model';
import { AppService } from '@src/core/service';
import { AppConfigService } from '@src/core/service/appconfig.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})

export class LayoutComponent implements OnInit, AfterViewInit {

  // layout related config
  layoutType: string;
  layoutwidth: string;
  topbar: string;
  mode: string;
  sidebartype: string;

  userClaims: IUser | undefined;
  userName: string = '';

  /* Telerik Report */
  ServerUrl: any;
  viewerContainerStyle = {
    position: 'relative',
    height: '100%',
    left: '0px',
    right: '0px',
  };

  public getScreenWidth: any;
  public getScreenHeight: any;
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight - 75;
    return [this.getScreenWidth, this.getScreenHeight];
  }

  constructor(private eventService: EventService,
    private _appService: AppService,
    @Inject(LOCALE_ID) public localeId: string, public intlService: IntlService,
    public appConfigService: AppConfigService) { }

  ngOnInit() {
    this.ServerUrl = {
      url: CrazyKey.decrypt(this.appConfigService.appConfigDetails.ServerUrl, environment._privateKey),
      username: CrazyKey.decrypt(this.appConfigService.appConfigDetails.Username, environment._privateKey),
      password: CrazyKey.decrypt(this.appConfigService.appConfigDetails.Password, environment._privateKey),
    };

    // console.log(CrazyKey.encrypt(this.appConfigService.appConfigDetails.ServerUrl, environment._privateKey));


    // default settings
    this.layoutType = LAYOUT_VERTICAL;
    this.layoutwidth = LAYOUT_WIDTH;
    this.topbar = TOPBAR;
    this.mode = LAYOUT_MODE;
    this.sidebartype = SIDEBAR_TYPE;

    // listen to event and change the layout, theme, etc
    this.eventService.subscribe('changeLayout', (layout) => {
      this.layoutType = layout;
    });

    this.LayoutWidth(this.layoutwidth);

    this.eventService.subscribe('changeWidth', (width) => {
      this.layoutwidth = width;
      this.LayoutWidth(this.layoutwidth);
    });

    // listen to event and change the layout, theme, etc
    this.eventService.subscribe('changeSidebartype', (layout) => {
      this.sidebartype = layout;
      this.changeSidebar(this.sidebartype);
    });

    // Change Mode
    this.eventService.subscribe('changeMode', (mode) => {
      this.mode = mode;
      this.changeMode(this.mode);
    });

    this.changeSidebar(this.sidebartype);
    this.changeMode(this.mode);

    this.userClaims = this._appService.user;

    if (this.userClaims) {
      LocalString.LocaleID = CrazyKey.decrypt(this.userClaims.LanguageCulture, environment._privateKey);
      this.localeId = CrazyKey.decrypt(this.userClaims.LanguageCulture, environment._privateKey);

      LocalString.CurrencySymbol = this.userClaims.CurrencySymbol;
      LocalString.Currency = CrazyKey.decrypt(this.userClaims.Currency, environment._privateKey);
      LocalString.CurrencyCode = CrazyKey.decrypt(this.userClaims.CurrencyCode, environment._privateKey);
      if (this.localeId === 'hi-IN') {
        this.localeId = 'en-IN';
      }
      if (LocalString.LanguageCulture !== this.localeId) {
        (<CldrIntlService>this.intlService).localeId = this.localeId;
        LocalString.LanguageCulture = this.localeId;
      }

      LocalString.DateTimeFormat = CrazyKey.decrypt(this.userClaims.DateTimeFormat, environment._privateKey);
      LocalString.DateFormat = CrazyKey.decrypt(this.userClaims.DateFormat, environment._privateKey);
      LocalString.TimeFormat = CrazyKey.decrypt(this.userClaims.TimeFormat, environment._privateKey);
      LocalString.TenantID = CrazyKey.decrypt(this.userClaims.TenantID, environment._privateKey);
      this.userName = CrazyKey.decrypt(this.userClaims.UserName, environment._privateKey);
    }
  }

  // Theme Drk Light Mode
  changeMode(value) {
    switch (value) {
      case "light":
        document.body.setAttribute('data-bs-theme', 'light');
        break;
      case "dark":
        document.body.setAttribute('data-bs-theme', 'dark');
        break;
      default:
        document.body.setAttribute('data-bs-theme', 'light');
        break;
    }
  }

  changeSidebar(value) {
    switch (value) {
      case "light":
        document.body.setAttribute('data-sidebar', 'light');
        document.body.setAttribute('data-topbar', 'dark');
        document.body.removeAttribute('data-sidebar-size');
        document.body.removeAttribute('data-layout-size');
        document.body.removeAttribute('data-keep-enlarged');
        document.body.classList.remove('vertical-collpsed');
        document.body.removeAttribute('data-layout-scrollable');
        break;
      case "compact":
        document.body.setAttribute('data-sidebar-size', 'small');
        document.body.setAttribute('data-sidebar', 'dark');
        document.body.removeAttribute('data-topbar');
        document.body.removeAttribute('data-layout-size');
        document.body.removeAttribute('data-keep-enlarged');
        document.body.classList.remove('sidebar-enable');
        document.body.classList.remove('vertical-collpsed');
        document.body.removeAttribute('data-layout-scrollable');
        break;
      case "dark":
        document.body.setAttribute('data-sidebar', 'dark');
        document.body.removeAttribute('data-topbar');
        document.body.removeAttribute('data-layout-size');
        document.body.removeAttribute('data-keep-enlarged');
        document.body.removeAttribute('data-sidebar-size');
        document.body.classList.remove('sidebar-enable');
        document.body.classList.remove('vertical-collpsed');
        document.body.removeAttribute('data-layout-scrollable');
        break;
      case "icon":
        document.body.classList.add('vertical-collpsed');
        document.body.setAttribute('data-sidebar', 'dark');
        document.body.removeAttribute('data-layout-size');
        document.body.setAttribute('data-keep-enlarged', "true");
        document.body.removeAttribute('data-topbar');
        document.body.removeAttribute('data-layout-scrollable');
        break;
      case "colored":
        document.body.classList.remove('sidebar-enable');
        document.body.classList.remove('vertical-collpsed');
        document.body.setAttribute('data-sidebar', 'colored');
        document.body.removeAttribute('data-layout-size');
        document.body.removeAttribute('data-keep-enlarged');
        document.body.removeAttribute('data-topbar');
        document.body.removeAttribute('data-layout-scrollable');
        document.body.removeAttribute('data-sidebar-size');
        break;
      default:
        document.body.setAttribute('data-sidebar', 'dark');
        break;
    }
  }

  ngAfterViewInit() {
  }

  LayoutWidth(width: string) {
    switch (width) {
      case "fluid":
        document.body.setAttribute("data-layout-size", "fluid");
        document.body.classList.remove("vertical-collpsed");
        document.body.removeAttribute("data-layout-scrollable");
        break;
      case "boxed":
        document.body.setAttribute("data-layout-size", "boxed");
        document.body.classList.add("vertical-collpsed");
        document.body.removeAttribute("data-layout-scrollable");
        break;
      // case "scrollable":
      //   document.body.removeAttribute("data-layout-size");
      //   document.body.setAttribute("data-layout-scrollable", "true");
      //   document.body.setAttribute("data-layout-size", "fluid");
      //   document.body.classList.remove("right-bar-enabled", "vertical-collpsed");
      default:
        document.body.setAttribute("data-layout-size", "fluid");
        break;
    }
  }

  /**
   * Check if the vertical layout is requested
   */
  isVerticalLayoutRequested() {
    return this.layoutType === LAYOUT_VERTICAL;
  }

  /**
   * Check if the horizontal layout is requested
   */
  isHorizontalLayoutRequested() {
    return this.layoutType === LAYOUT_HORIZONTAL;
  }
}
