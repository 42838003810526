import { Injectable } from '@angular/core';
import { AppConfig } from '../model/appconfig.model';
import * as configJson from '../../assets/AppConfig.json';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private appConfig: AppConfig = new AppConfig();
  constructor(private http: HttpClient) { }

  // loadAppConfig() {
  //   this.appConfig = configJson;
  //   return this.appConfig;
  // }

  // setConfig(newConfig: any): void {
  //   this.appConfig = newConfig;
  // }

  // getConfig(): AppConfig {
  //   return this.appConfig;
  // }

  // loadConfig(dynamicConfigService: AppConfigService) {
  //   // Fetch or set initial configuration here
  //   const initialConfig = dynamicConfigService.getConfig;
  //   dynamicConfigService.setConfig(initialConfig);
  //   return () => Promise.resolve();
  // }
  
  loadAppConfig() {
    return this.http.get<AppConfig>('../../assets/AppConfig.json').subscribe((res: AppConfig) => {
      this.appConfig = res;
    });
  }

  get appConfigDetails(): AppConfig {
    return this.appConfig;
  }

}
