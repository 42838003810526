<!-- Right Sidebar -->

<div class="right-bar">

  <ngx-simplebar class="h-100">
    <div class="rightbar-title px-3 py-4">
      <a href="javascript:void(0);" class="right-bar-toggle float-end" (click)="hide();">
        <i class="mdi mdi-close noti-icon"></i>
      </a>
      <h5 class="m-0">Themes</h5>
      <!-- Theme Type -->
      <!-- <div class="themes-head">
        <div class="grid grid-cols-2 gap-4 pt-8">
          <div class="w-full text-center relative">
            <div (click)="selectThemeType('light')">
              <img src="assets/logo/theme-light.png" class="cursor-pointer theme-img">
            </div>
            <div class="checkType" *ngIf="activeThemeType === 'light'">
              <img class="checkImage" src="assets/logo/check.png" alt="">
            </div>
            <p class="mt-1.5 themeName">Light</p>
          </div>

          <div class="w-full text-center relative">
            <div (click)="selectThemeType('dark')">
              <img src="assets/logo/theme-dark.png" class="cursor-pointer theme-img">
            </div>
            <div class="checkType" *ngIf="activeThemeType === 'dark'">
              <img class="checkImage" src="assets/logo/check.png" alt="">
            </div>
            <p class="themeName">Dark</p>
          </div>
        </div>
      </div> -->
      <div *ngIf="activeThemeType === 'light'">
        <div class="grid grid-cols-2 text-center mt-8">
          <div *ngFor="let item of lightThemes" class="relative">
            <button kendoButton class="theme-selection" [style]="item.displayColor" (click)="loadTheme(item.themeName)"
              (mouseover)="MouseOver(item.themeName)" (mouseleave)="MouseLeave()"></button>
            <div class="checkImg" *ngIf="activeThemeName === item.themeName">
              <img class="checkImage" src='assets/logo/check.png'>
            </div>
            <p class="themeName">{{item.displayName}}</p>
          </div>
        </div>
      </div>
      <div class="relative" *ngIf="activeThemeType === 'dark'">
        <div class="grid grid-cols-2 gap-4 text-center mt-8">
          <div *ngFor="let item of darkThemes" class="relative">
            <button kendoButton class="theme-selection" [style]="item.displayColor" [disabled]="true"
              (click)="loadTheme(item.themeName)"></button>
            <div class="checkImg" *ngIf="activeThemeName === item.themeName">
              <img class="checkImage" src='assets/logo/check.png'>
            </div>
            <p class="themeName">{{item.displayName}}</p>
          </div>
        </div>
      </div>
    </div>
  </ngx-simplebar>
</div>
<!-- /Right-bar -->

<!-- Right bar overlay-->
<div class="rightbar-overlay"></div>
