export const nameRegex = /^[a-zA-Z ](\w\.?)+$/;
export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export const emailRegex = /^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,7}$/;
export const mobileRegex = /^(0|91)?[6-9][0-9]{9}$/;
export const alphaNumericOnly = /^[a-zA-Z0-9]*$/;
export const indianPincode = /^[1-9]{1}[0-9]{5}$/;
export const IFSC = /^[a-zA-Z0-9_]+$/;
export const PAN = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
export const onlyNumbers = /^[0-9]*$/;
export const aadharNumber = /^[0-9]*$/;
export const VoterIDNo = /^([a-zA-Z]){3}([0-9]){7}?$/;
export const RationcardNo = /^[0-9]*$/;
export const EBNumber = /^[0-9]*$/;
export const DLno = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
export const PassportNo = /^[0-9]*$/;

export class InputRegex {
  static Name = /^[a-zA-Z .,-]+$/;
  static Password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_#])[A-Za-z\d@$!%*?&_#]{8,}$/;
  static Email = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
  // static email = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,10}$/;
  static email = /^[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}$/;
  static Mobile = /^(0|91)?[6-9][0-9]{9}$/;
  static alphaNumericOnly = /^[a-zA-Z0-9]*$/;
  static IndianPincode = /^[1-9]{1}[0-9]{5}$/;
  // static IFSC = /^[a-zA-Z0-9_]+$/;
  static PAN = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
  static Numeric = /^[0-9]*$/;
  static IFSC = /^[A-Za-z]{4}\d{7}$/;
}
