import { AbstractControl, NgForm } from '@angular/forms';
import { UrlSegment } from '@angular/router';

export class Guru {
  static isValidObj(obj: any): boolean {
    return obj !== undefined && obj !== null;
  }
  static isValidList(obj: any): boolean {
    return this.isValidObj(obj) && obj.length > 0;
  }
  static isString(obj: any): boolean {
    return this.isValidObj(obj) && typeof obj === 'string';
  }
  static isNumber(obj: any): boolean {
    return this.isValidObj(obj) && typeof obj === 'number';
  }
  static isEmptyString(obj: any): boolean {
    return obj === null || (obj && this.isString(obj) && obj === '');
  }
  static isValidString(obj: any): boolean {
    return this.isString(obj) && obj !== '';
  }
  static isValidNumber(obj: any): boolean {
    return this.isNumber(obj) && obj !== 0 && obj !== '';
  }
  static replaceSplChar(Text: string): string {
    return (this.isValidObj(Text) ? Text.trim() // Remove surrounding whitespace.
      .toLowerCase() // LowerCase.
      // eslint-disable-next-line max-len
      .replace(/[^a-z0-9]+/g, '-')  // Find everything that is not a lowercase letter or number, one or more times, globally, and replace it with a dash.
      .replace(/^-+/, '') // Remove all dashes from the beginning of the string.
      .replace(/-+$/, '') // Remove all dashes from the end of the string.
      .toUpperCase() // UpperCase.
      : '');
  }
  static ConvertToUrl(segments: UrlSegment[]): string {
    if (this.isValidList(segments)) {
      let url = '';
      segments.forEach(segment => {
        url += segment.path;
      });
      return url;
    }
    return '';
  }
  static get WindowUrl(): string {
    let url = '';
    if (this.isValidObj(window?.location?.pathname)) {
      url = window?.location?.pathname;
    }
    if (url !== '' && this.isValidObj(window?.location?.search)) {
      url += window?.location?.search;
    }
    return url;
  }
  static animateClass(obj: any): void {
    obj.classList.add('bounce');
    setTimeout(() => {
      obj.classList.remove('bounce');
    }, 1000);
  }
  static resetFormControlValidation(control: AbstractControl | undefined): void {
    if (control) {
      control.markAsPristine();
      control.markAsUntouched();
      control.updateValueAndValidity();
    }
  }
  static resetFormValidation(frm: NgForm | undefined): void {
    if (frm && frm.controls) {
      Object.keys(frm.controls)?.forEach(key => {
        this.resetFormControlValidation(frm.controls[key]);
      });
      frm.form.markAsPristine();
      frm.form.markAsUntouched();
      frm.form.updateValueAndValidity();
    }
  }
  static CurrencyFormatter(params: any): string {
    const usdFormate = new Intl.NumberFormat('ta-IN');
    return usdFormate.format(params.value);
  }
  static autoSizeAll(
    gridColumnApi: {
      getAllColumns: () => any[];
      autoSizeColumns: (arg0: any[], arg1: boolean) => void;
    },
    skipHeader = false
  ): void {
    const allColumnIds: any[] = [];
    gridColumnApi.getAllColumns().forEach(column => {
      allColumnIds.push(column.colId);
    });
    gridColumnApi.autoSizeColumns(allColumnIds, skipHeader);
  }
}
