import { Injectable } from '@angular/core';
import { CookieKey, CrazyKey, Guru } from '@src/core/helper';
import { ILoginResponse, IRequestType, IUser, IWebError, IWebResponse, LoginModel, RenewBearerToken } from '@src/core/model';
import { environment } from '@src/environments/environment';
import jwt_decode from "jwt-decode";
import { CookieService } from 'ngx-cookie-service';
import { Observable, of, Subject } from 'rxjs';
import { HttpService } from './http.service';
import { KeyService } from './key.service';
import { IRefreshToken } from '@src/app/shared/app-global';
import { Menu } from '@src/app/layouts/sidebar/menu.model';
export interface AppNotify {
  user: string;
  /** App data specifies the stored key,  default: `app` */
  menu: string;
}
@Injectable()
export class AppService {

  private _user!: IUser | undefined;
  private _mappedMenus!: Menu[] | undefined;
  private notify$ = new Subject<AppNotify>();
  private _privateKey: string = environment._privateKey;

  loginCredentials: LoginModel = new LoginModel();

  constructor(
    private _http: HttpService,
    private _srvCookie: CookieService,
    private _srvKey: KeyService,
  ) { }

  get menus(): Menu[] {
    try {
      // if (!this._mappedMenus) {
      const _menuCookieKey = this._srvKey.HashedCookieKey(CookieKey.Mappedmenu);
      const _menuCookieStr = localStorage.getItem(_menuCookieKey) || '';
      const temp = CrazyKey.decrypt(_menuCookieStr, environment._privateKey);
      if (!Guru.isValidString(_menuCookieStr)) {
        return [];
      }
      this._mappedMenus = JSON.parse(temp) as Menu[];
      // }
      return this._mappedMenus;
    } catch (error) {
      return [];
    }
  }
  get user(): IUser | undefined {
    try {
      // if (!this._user) {
      let bearerToken = this._srvCookie.get(CookieKey.Api);
      this._user = jwt_decode<IUser>(bearerToken);
      // }
      return this._user;
    } catch (error) {
      return undefined;
    }
  }
  get notify(): Observable<AppNotify> {
    return this.notify$.asObservable();
  }

  SignIn(Username: string, Passoword: string, RememberMe: boolean): Observable<IWebResponse<ILoginResponse> | IWebError> {
    return this._http.get<ILoginResponse>(`auth/login/${Username}/${Passoword}/${RememberMe}`, {
      RequestType: IRequestType.Guest
    });
  }
  GetRefreshToken(refToken: RenewBearerToken) {
    return this._http.post(`/authorize/token/renew-for-tenant`, refToken, { RequestType: IRequestType.Anonymus });
  }
  refreshAccessToken(): Observable<string> {
    // this is just simulating a delay to make it more realistic
    return of('foo').pipe();
  }
  SignOut(): void {
    this._srvKey.SignOut();
  }
}

