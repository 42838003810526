import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GridModule } from '@progress/kendo-angular-grid';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { NavigationModule } from '@progress/kendo-angular-navigation';
import { IconsModule } from '@progress/kendo-angular-icons';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from "@progress/kendo-angular-dateinputs";
import { NotificationModule } from '@progress/kendo-angular-notification';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ChangePasswordComponent } from './change-password.component';
import { PasswordService } from '../../service/password.service';


@NgModule({
  declarations: [
    ChangePasswordComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    GridModule,
    DialogsModule,
    LabelModule,
    InputsModule,
    NavigationModule,
    IconsModule,
    LayoutModule,
    ButtonsModule,
    DropDownListModule,
    DateInputsModule,
    NotificationModule,
    ReactiveFormsModule
  ],
  providers: [PasswordService],
  exports: [ChangePasswordComponent]
})
export class ChnagePasswordModule { }
