import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SimplebarAngularModule } from 'simplebar-angular';
import { LayoutComponent } from './layout.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { TopbarComponent } from './topbar/topbar.component';
import { FooterComponent } from './footer/footer.component';
import { RightsidebarComponent } from './rightsidebar/rightsidebar.component';
import { HorizontalComponent } from './horizontal/horizontal.component';
import { VerticalComponent } from './vertical/vertical.component';
import { HorizontaltopbarComponent } from './horizontaltopbar/horizontaltopbar.component';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { IconsModule } from '@progress/kendo-angular-icons';
import { PopupModule } from '@progress/kendo-angular-popup';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { DialogService, DialogsModule } from '@progress/kendo-angular-dialog';
import { ChnagePasswordModule } from '../shared/components/change-password/change-password.module';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('src/app/admin/dashboard/dashboard.module').then(m => m.dashboardModule)
  },
  {
    path: 'role',
    loadChildren: () => import('src/app/admin/Role/roles.module').then(m => m.RolesModule)
  },
  {
    path: 'user-job-title',
    loadChildren: () => import('src/app/admin/user-job-title/user-job-title.module').then(m => m.UserJobTitleModule)
  },
  {
    path: 'employee',
    loadChildren: () => import('src/app/admin/user/user.module').then(m => m.UserModule)
  },
  {
    path: 'request',
    loadChildren: () => import('src/app/admin/request/request.module').then(m => m.RequestModule)
  },
  {
    path: 'first-approval',
    loadChildren: () => import('src/app/admin/first-approval/first-approval.module').then(m => m.FirstApprovalModule)
  },
  {
    path: 'final-approval',
    loadChildren: () => import('src/app/admin/final-approval/final-approval.module').then(m => m.FinalApprovalModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('src/app/admin/reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'pending',
    loadChildren: () => import('src/app/admin/pending/pending.module').then(m => m.PendingModule)
  },
  {
    path: 'approved',
    loadChildren: () => import('src/app/admin/approved/approved.module').then(m => m.ApprovedModule)
  },
  {
    path: 'rejected',
    loadChildren: () => import('src/app/admin/rejected/rejected.module').then(m => m.RejectedModule)
  },
  {
    path: 'leave-form',
    loadChildren: () => import('src/app/admin/leave-form/leave-form.module').then(m => m.LeaveFormModule)
  },
  {
    path: 'error',
    loadChildren: () => import('src/app/shared/components/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule)
  },
];

@NgModule({
  declarations: [LayoutComponent, SidebarComponent, TopbarComponent, FooterComponent, RightsidebarComponent, HorizontalComponent, VerticalComponent, HorizontaltopbarComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    RouterModule,
    SimplebarAngularModule,
    ButtonsModule,
    IconsModule,
    PopupModule,
    LayoutModule,
    DialogsModule,
    ChnagePasswordModule,
    // TelerikReportingModule
  ],
  providers: [
    DialogService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class LayoutsModule { }
